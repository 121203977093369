<template>
    <el-dialog :title="editId ? '编辑文档' : '新增文档'" ref="dialog" :model-value="dialogVisible" :before-close="handleClose" width="65%" 
        :close-on-click-modal="false" :z-index="1000" >
        <el-form :rules="rules" ref="form" :model="formData" v-loading="loading">
            <el-form-item label="文档名称" prop="title">
                <el-input v-model="formData.title" placeholder="请填写文档名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="文档内容" prop="content">
                <tinymce ref="tinymce" :defaultContent="formData.content" />
            </el-form-item>
        </el-form>

        <template #footer>
            <span class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="handleConfirm">确 定</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import { ref, getCurrentInstance } from 'vue'
import util from '@/lin/util/util'
import infoModel from '@/model/information'
import Tinymce from '@/component/base/tinymce'
import { ElMessage } from 'element-plus'

export default {
    emits: ['update'],
    components: {
        Tinymce,
    },
    setup(props) {
        const formData = ref({})
        const loading = ref(false)
        const tinymce = ref(null)
        const editId = ref()
        const dialogVisible = ref(false)
        const ctx = getCurrentInstance()

        const init = (data) => {
            console.log('init', data);
            if (data.id) {
                editId.value = data.id
                formData.value = data
            } else {
                editId.value = ''
                formData.value = {}
            }
            dialogVisible.value = true
        }

        // 确认
        const handleConfirm = () => {
            formData.value.content = tinymce.value.content
            ctx.refs.form.validate(async valid => {
                if (valid) {
                    let res
                    try {
                        loading.value = true
                        if (!editId.value) res = await infoModel.addInfo(formData.value)
                        else res = await infoModel.editInfo(editId.value, formData.value)
                        ElMessage.success(`${res.message}`)
                        ctx.emit('update')
                        handleClose()
                    } catch(err) {
                        console.log(err);
                    } finally {
                        loading.value = false
                    }
                } else {
                    ElMessage.error('请将信息填写完整')
                }
            })
        }

        // 关闭
        const handleClose = () => {
            resetForm()
            dialogVisible.value = false
        }

        // 定义验证规则
        const getRules = () => {
            const checkInfo = (rule, value, callback) => {
                if (!value || value.length == 0) {
                    callback(new Error('请将信息补充完整'))
                }
                callback()
            }
            const rules = {
                title: [{ validator: checkInfo, trigger: 'blur', required: true }],
                content: [{ validator: checkInfo, trigger: 'blur', required: true }],
            }
            return { rules }
        }

        // 重置
        const resetForm = () => {
            ctx.refs.form.resetFields()
        }

        const { rules } = getRules()

        return {
            editId,
            dialogVisible,
            rules,
            loading,
            tinymce,
            formData,
            handleClose,
            handleConfirm,
            init,
            resetForm
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
